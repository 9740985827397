import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_m = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - M'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>M</h2>
     <LatQuotesIntro />
     <p><b>Magister rerum usus; magistra rerum experientia</b> - Use is the master of things; experience is the mistress of things.</p>
<p><b>Major continet in se minus</b> - The greater contains the less.</p>
<p><b>Majus est delictum se ipsum occidere quam alium</b> - It is a greater crime to kill one s self than another.</p>
<p><b>Mala fide</b> - In bad faith.</p>
<p><b>Mala grammatica non vitiat chartam</b> - Bad grammar does not vitiate a deed.</p>
<p><b>Mala in se</b> - Bad in themselves.</p>
<p><b>Mala prohibita</b> - Crimes prohibited.</p>
<p><b>Malitia supplet aesatem</b> - Malice supplies age.</p>
<p><b>Malo animo</b> - With evil intent.</p>
<p><b>Mandamus</b> - We command.</p>
<p><b>Maximus magister erroris populus est</b> - The people are the greatest master of error.</p>
<p><b>Melior est conditio possidentis, ubi neuter jus habet</b> - Better is the condition of the possessor where neither of the two has the right.</p>
<p><b>Melior testatoris in testamentis spectanda est</b> - In wills the intention of a testator is to be regarded.</p>
<p><b>Meliorem conditionem suam facere potest minor deteriorem nequaquam</b> - A minor can make his position better, never worse.</p>
<p><b>Mens rea</b> - Guilty state of mind.</p>
<p><b>Mentiri est contra mentem ire</b> - To lie is to act against the mind.</p>
<p><b>Merito beneficium legis amittit, qui legem ipsam subvertere intendit</b> - He justly loses the benefit of the law who seeks to infringe the law.</p>
<p><b>Minatur innocentibus qui parcit nocentibus</b> - He threatens the innocent who spares the guilty.</p>
<p><b>Misera est servitus, ubi jus est vagum aut incertum</b> - It is a miserable slavery where the law is vague or uncertain.</p>
<p><b>Mors dicitur ultimum supplicium</b> - Death is called the extreme penalty.</p>
<p><b>Muilta exercitatione facilius quam regulis percipies</b> - You will perceive many things more easily by experience than by rules.</p>

   </Layout>
  )
}

export default LatQuotes_m
